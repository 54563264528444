<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems"
			:loading="loading" loading-text="加载中...">
            <template v-slot:top>
				<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px">
					<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
						<v-btn>全部待交付</v-btn>
						<v-btn>评估后待交付</v-btn>
						<v-btn>已交付</v-btn>
					</v-btn-toggle>
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="交付完成日期起" readonly v-bind="attrs"
								v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate2" label="交付完成日期止" readonly v-bind="attrs"
								v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-checkbox v-model="showComplete" label="显示已完成订单" hide-details dense @change="fetchData"/>
					<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto;max-width:150px;"
						append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
					<v-btn color="primary" @click.stop="exportXLS">导出</v-btn>
				</div>
                <v-divider/>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            </template>
			
			<template v-slot:item.evalConclusion="{ item }">
				{{item.evalConclusion ? (item.evalConclusion.gastroPrompt3 ? '4袋' : '常规') : ''}}
			</template>
            <template v-slot:item.progress.evalConclusion="{ item }">
                {{formatTime(item.progress.evalConclusion)}}
            </template>
            <template v-slot:item.progress.requestSupplies="{ item }">
                {{formatTime(item.progress.requestSupplies)}}
            </template>
            <template v-slot:item.progress.deliverSupplies="{ item }">
                {{formatTime(item.progress.deliverSupplies)}}
            </template>
            <template v-slot:item.idcard="{ item }">
                {{getMaskedIdcard(item)}}
            </template>
            <template v-slot:item.opts="{ item }">
                {{getOptsStr(item)}}
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent max-width="540">
				<v-card>
					<v-card-title>交付清肠药</v-card-title>
					<v-card-text>
						<v-form ref="dsform" :readonly="locked">
							<v-container>
								<v-row>
									<v-col cols="12" sm="4"><v-text-field label="姓名" v-model="currentItem.pii.username" readonly dense/></v-col>
									<v-col cols="12" sm="4">
										<v-select label="交付方式" v-model="deliverSuppliesWay" placeholder="请选择交付方式"
											:items="['邮寄','面交']" dense :rules="wayRules" required :readonly="phase2"/>
									</v-col>
									<v-col cols="12" sm="4">
										<v-select label="申请数量" v-model="deliverSuppliesQuantity" placeholder="请选择申请数量"
											:items="['3','4']" dense :rules="wayRules" required :readonly="phase2"/>
									</v-col>
								</v-row>
								<v-textarea label="邮寄地址" v-model="deliverSuppliesAddress" dense rows="2"
									:disabled="deliverSuppliesWay!=='邮寄'" :rules="addressRules" :readonly="phase2"/>
								<v-text-field label="快递单号" v-model="deliverSuppliesTracking" dense
									:disabled="deliverSuppliesWay!=='邮寄'" :readonly="!phase2"/>
							</v-container>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<template v-if="phase2">
							<v-btn color="primary" @click="saveDelivered" :loading="loadingsave" :disabled="loadingsave||locked">确认已交付清肠药</v-btn>
						</template>
						<template v-else>
							<v-btn color="primary" @click="save" :loading="loadingsave" :disabled="loadingsave||locked">保存</v-btn>
						</template>
						<v-btn @click="dialog=false">取消</v-btn>
					</v-card-actions>
				</v-card>
        </v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime,formatDate} from '../utils'
	import store from '../store.js'

    export default {
        name: 'Order',
        data() {
            return {
				authed: false,
				phase2: false,
                headers: [
                    {text:'', value:'actions', width:40, sortable: false},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'电话', value:'pii.phone', width:120},
                    {text:'清肠药评估', value:'evalConclusion', width:140},
                    {text:'交付方式', value:'deliverSupplies.Way', width:120},
                    {text:'申请数量', value:'deliverSupplies.Quantity', width:100},
                    {text:'邮寄地址', value:'deliverSupplies.Address', width:300},
                    {text:'快递单号', value:'deliverSupplies.Tracking', width:150},
                    {text:'评估完成时间', value:'progress.evalConclusion', width:180},
                    {text:'信息更新时间', value:'progress.requestSupplies', width:180},
                    {text:'交付完成时间', value:'progress.deliverSupplies', width:180},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'套餐内容', value:'opts', sortable: false, width:240},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'idcard', width:120},
                ],
                items: [],
                loading: false,
                dialog: false,
                loadingsave: false,
				locked: false,
                currentItem: {pii:{}},
                currentState: false,
				deliverSuppliesWay: '',
				deliverSuppliesQuantity: '3',
				deliverSuppliesAddress: '',
				deliverSuppliesTracking: '',
				wayRules: [v => !!v || '此项为必填'],
				addressRules: [v => !!v || (this.deliverSuppliesWay!=='邮寄') || '此项为必填'],
				filter: 0,
				options: {},
				totalItems: 0,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
				showComplete: false,
            }
        },
        mounted() {
			this.phase2 = this.$hasPrivilege('交付清肠药');
			this.authed = this.phase2 || this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
            this.formatTime = formatTime;
//			this.pdate1 = formatDate(Date.now());
//			this.pdate2 = formatDate(Date.now());
			this.$watch(()=>store.currentRegion, this.fetchData);
            this.fetchData();
		},
        methods: {
            async fetchData() {
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const _ = db.command;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(filter).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(filter).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
            },
			getFilter() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [{'opts.GIE':_.neq('胃镜')}];
				if (!this.showComplete) {
					filter.push(_.and([
						{'endoscopyResult.pathology' : _.neq('无')}, {'progress.pathology' : _.exists(false)}, {'giveupInterpretation':_.exists(false)}	//未完成的订单
					]))
				}
				if (this.$hasPrivilege('交付清肠药')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				if (t1 && t2) {
					filter.push({'progress.deliverSupplies':_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({'progress.deliverSupplies':_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({'progress.deliverSupplies':_.lt(t2)});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				filter.push({category:_.neq('clinic')});
				filter.push({region:store.currentRegion});
				const filters = [
					[{'progress.deliverSupplies':_.exists(false)}],
					[{'progress.deliverSupplies':_.exists(false)}, {'evalConclusion':_.exists(true)}, {'evalConclusion.anesConclusion':_.neq('未通过')}],
					[{'progress.deliverSupplies':_.exists(true)}],
				];
				const f = filter.concat(filters[this.filter]);
				return _.and(f);
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
//				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
			},
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
            editItem(item) {
                this.currentItem = item;
				this.deliverSuppliesWay = item.deliverSupplies?.Way || '';
				this.deliverSuppliesQuantity = item.deliverSupplies?.Quantity || '';
				this.deliverSuppliesAddress = item.deliverSupplies?.Address || '';
				this.deliverSuppliesTracking = item.deliverSupplies?.Tracking || '';
				this.locked = !!item.progress.deliverSupplies;
                this.dialog = true;
            },
            async save() {
				this.$refs.dsform.validate();
				if (!this.$refs.dsform.validate()) return;
				this.loadingsave = true;
				try {
					const id = this.currentItem._id;
					if (this.deliverSuppliesWay === '面交') {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'deliverSuppliesByFace',
								data: { id, Quantity:this.deliverSuppliesQuantity }
							}
						});
					} else {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'requestSuppliesByMail',
								data: { id, Quantity:this.deliverSuppliesQuantity, Address:this.deliverSuppliesAddress }
							}
						});
					}
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);
				}
				this.loadingsave = false;
			},
			async saveDelivered() {
				this.loadingsave = true;
				try {
					const id = this.currentItem._id;
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'deliverSuppliesByMail',
							data: { id, Tracking:this.deliverSuppliesTracking }
						}
					});
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);
				}
				this.loadingsave = false;
			},
			async exportXLS() {
				const MAX_LIMIT = 100;
				const filter = this.getFilter();
				const db = this.$tcbapp.database();
				const countResult = await db.collection('wp2order').where(filter).count();
				const total = countResult.total;
				const batchTimes = Math.ceil(total / MAX_LIMIT);
				const tasks = [];
				for (let i = 0; i < batchTimes; i++) {
					const promise = db.collection('wp2order').where(filter).skip(i * MAX_LIMIT).limit(MAX_LIMIT).get();
					tasks.push(promise);
				}
				const res = (await Promise.all(tasks)).reduce((acc, cur) => {
					return {
						data: acc.data.concat(cur.data),
						errMsg: acc.errMsg,
					}
				});
				const header = [[
					'姓名',
					'清肠药评估',
					'交付方式',
					'申请数量',
					'邮寄地址',
					'快递单号',
					'评估完成时间',
					'信息更新时间',
					'交付完成时间',
					'健康顾问',
					'套餐内容',
				]];
				const data = res.data.map(x => [
					x.pii.username,
					x.evalConclusion ? (x.evalConclusion.gastroPrompt3 ? '4袋' : '常规') : '',
					x.deliverSupplies?.Way || '',
					x.deliverSupplies?.Quantity || '',
					x.deliverSupplies?.Address || '',
					x.deliverSupplies?.Tracking || '',
					formatTime(x.progress.evalConclusion),
					formatTime(x.progress.requestSupplies),
					formatTime(x.progress.deliverSupplies),
					x.consultantName,
					this.getOptsStr(x),
				]);
				const t = '清肠药交付';
				const filename = "清肠药交付.xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data), {dateNF: 'YYYY-MM-DD HH:mm:ss'});
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
        },
		watch: {
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			}
		}
    }
</script>